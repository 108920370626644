.descriptions-children
.ant-descriptions-view {
  border-top: none!important;
}

.descriptions-children
.ant-descriptions-view
.ant-descriptions-item {
  padding-bottom: 0;
}

.descriptions-children
.ant-descriptions-view
.ant-descriptions-item
.ant-descriptions-item-label {
  color: rgba(0, 0, 0, 0.45);
  font-weight: 400;
}
