/* NOTE: Position "Contact Us" button with text */

.contact-us {
  width: 150px;
  border-radius: 50px;
}

.contact-us-mobile {
  width: 100%;
  border-radius: 0px;
}

.contact-us.ant-float-btn-square .ant-float-btn-body {
  border-radius: 50px !important;
}

.contact-us-mobile.ant-float-btn {
  inset-block-end: 0;
  inset-inline-end: 0;
}

.contact-us-mobile.ant-float-btn .ant-float-btn-body {
  border-radius: 0;
}

.contact-us .ant-float-btn-content,
.contact-us-mobile .ant-float-btn-content {
  display: flex;
  padding: 2px 20px !important;
  border-radius: 50px;
  flex-direction: row !important;
}

.contact-us .ant-float-btn-content .ant-float-btn-description,
.contact-us-mobile .ant-float-btn-content .ant-float-btn-description {
  font-weight: 600;
  margin-left: 8px;
  white-space: nowrap;
}
