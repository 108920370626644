/* NOTE: Reduce top padding for collapse content box */
.collapse-table .ant-collapse-content-box {
  padding-top: 0 !important;
}

/* NOTE: Make header font bigger and bolder */
.collapse-table .ant-collapse-header > .ant-collapse-header-text {
  font-size: 16px;
  font-weight: 600;
}
