.assets-list-input .ant-upload-list {
  position: absolute;
  width: 100%;
}

.assets-list-input .ant-upload-wrapper {
  height: 70px;
}

.ant-upload-list-item {
 margin-top: 4px;
}

.minus-icon {
  margin-top: 5px;
}
