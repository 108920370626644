
/* NOTE: Remove left padding to get message centered */
.header-alerts .ant-alert {
  padding-left: 0;
}

/* NOTE: Hide icon, it doesn't look good and hard to align */
.header-alerts .ant-alert .anticon {
  display: none;
}

/* NOTE: Set max width so alert is aligned with navigation of the layout */
.header-alerts .ant-alert .ant-alert-content {
  width: 100%;
  margin: 0 auto;
  max-width: 1600px;
  text-align: center;
  padding-left: 20px;
}

/* NOTE: Drop margin from default markdown styles */
.header-alerts .ant-alert .ant-alert-message p {
  margin: 0;
}
