[data-amplify-authenticator] {
/*  --amplify-colors-background-primary: rgb(245, 245, 245);
  --amplify-colors-background-secondary: rgb(245, 245, 245);
  --amplify-colors-brand-primary-10: var(--amplify-colors-teal-100);
  --amplify-colors-brand-primary-80: var(--amplify-colors-teal-40);
  --amplify-colors-brand-primary-90: var(--amplify-colors-teal-20);
  --amplify-colors-brand-primary-100: var(--amplify-colors-teal-10);
  --amplify-colors-font-interactive: var(--amplify-colors-white);
  --amplify-components-tabs-item-active-color: var(--amplify-colors-white);
  --amplify-components-tabs-item-focus-color: var(--amplify-colors-white);
  --amplify-components-tabs-item-hover-color: var(--amplify-colors-orange);
  --amplify-components-authenticator-modal-width: var(--amplify-space-relative-full);
*/
  /*--amplify-components-authenticator-or-container-color:rgb(245, 245, 245)*/
  --amplify-components-authenticator-router-border-color: #f0f0f0
  --amplify-components-authenticator-router-border-style: solid;
  --amplify-components-authenticator-router-border-width: 1px;
  /*--amplify-components-authenticator-router-background-color: rgb(245, 245, 245);*/
  /*--amplify-components-button-font-weight: var(--amplify-font-weights-bold);*/
  /*--amplify-components-button-font-size: var(--amplify-components-fieldcontrol-font-size);*/
  --amplify-components-button-border-radius: 32px;
  --amplify-components-button-color: #3bb3e3;
  /*--amplify-components-button-hover-color: var(--amplify-colors-font-focus);*/
  /*--amplify-components-button-hover-background-color: var(--amplify-colors-brand-primary-10);*/
  /*--amplify-components-card-background-color: rgb(245, 245, 245);*/
  --amplify-components-field-font-size: 14px;
  /*--amplify-components-field-small-font-size: var(--amplify-font-sizes-small);*/
  /*--amplify-components-field-large-font-size: var(--amplify-font-sizes-large);*/
  /*--amplify-components-button-focus-color: #3bb3e3;*/
  /*--amplify-components-button-active-background-color: #3bb3e3;*/
  /*--amplify-components-button-active-color: #3bb3e3;*/
  --amplify-components-button-primary-background-color: #169bd7;
  /*--amplify-components-button-primary-hover-background-color: #169bd7;*/
  --amplify-components-button-primary-hover-background-color: #3bb3e3;
  --amplify-components-button-primary-color: #fff;
  --amplify-components-field-label-color: #253B80;
  --amplify-components-tabs-item-active-color: #169bd7;
  --amplify-components-tabs-item-active-border-color: #169bd7;
  --amplify-components-button-font-weight: 600;
  --amplify-components-tabs-item-font-weight: 400;
  --amplify-components-button-link-color: #169bd7;
  --amplify-components-authenticator-router-box-shadow: none;
  --amplify-components-fieldcontrol-border-width: 1px;
  /*--amplify-components-fieldcontrol-color: #253B80;*/
  /*--amplify-components-fieldcontrol-border-color: #3bb3e3;*/
  /*--amplify-components-fieldcontrol-quiet-focus-border-block-end-color: #3bb3e3;*/
  /*--amplify-components-fieldcontrol-quiet-focus-box-shadow: 0 0 0 2px rgba(5, 215, 255, 0.1);*/
  /*--amplify-components-fieldcontrol-focus-color: #253B80;*/
  --amplify-components-fieldcontrol-focus-border-color: #3bb3e3;
  --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px rgba(5, 215, 255, 0.1);
  /*--amplify-components-passwordfield-color: #253B80;*/
  /*--amplify-components-fieldcontrol-font-size: var(--amplify-components-field-font-size);*/
}

.amplify-input {
  --amplify-components-fieldcontrol-focus-border-color: #3bb3e3;
  color: #253B80;
}

[data-amplify-form] {
  a {
    color: #169bd7;
  }
}

[data-amplify-router] {
  border-color: #f0f0f0 !important;
  border-style: solid!important;
  border-width: 1px!important;
  border: 1px solid #f0f0f0;
}

.amplify-input[type='number'] {
  -moz-appearance: textfield;
}

.amplify-input[type='number']::-webkit-outer-spin-button,
.amplify-input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
