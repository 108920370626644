
/* NOTE: Remove box shadow of the no border card and corner radius */
.card-mobile.ant-card {
  border-radius: 0;
  box-shadow: none;
}

/* NOTE: Remove corner radius of the header */
.card-mobile.ant-card .ant-card-head {
  border-radius: 0;
}
