/*.input-boolean .ant-form-item-label { padding-bottom: 0; }*/

/*.mask-input .ant-form-item-control-input .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
    color: #253B80;
}

.mask-input .ant-form-item-control-input .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) .ant-input {
    color: #253B80;
}

.mask-input .ant-form-item-control-input .ant-input-affix-wrapper .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.25);
}*/

/*.ant-input,
.ant-input-number-affix-wrapper,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled),
.ant-input-number,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #F8F8FF;
}*/

.ant-input-number-disabled {
    background-color: transparent;
}

.ant-input-number-input[disabled] {
    color: rgba(0, 0, 0, 0.25);
}

.ant-form:not(.ant-form-inline) .ant-form-item .ant-form-item-label > label {
    align-items: baseline;
}

/* NOTE: Disable radio labels in the investment form */
.investment-form .ant-radio-wrapper-disabled .ant-typography{
    color: rgba(0, 0, 0, 0.25);
}

/* NOTE: Align checkbox to the top */
.ant-checkbox-wrapper .ant-checkbox {
    align-self: inherit;
    top: 3px;
}
