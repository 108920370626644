
/* NOTE: Hide tails of the step component */
.investment-card .ant-steps .ant-steps-item-tail {
  display: none !important;
}

/* NOTE: Make step items closer to each other */
.investment-card .ant-steps .ant-steps-item-content {
  min-height: 34px !important;
}

.investment-card .ant-steps-item:last-child .ant-steps-item-content {
  min-height: 24px !important;
}
