@media print {
    #root * {
        display: none !important;
    }
    .dialog,
    .dialog * {
        display: block !important;
    }
    .ant-layout-header,
    .ant-steps,
    .ant-modal-close,
    .ant-btn {
        display: none;
    }

    .ant-modal-root .ant-modal-wrap {
        width: 100% !important;
        height: 100% !important;
        border: none !important;
        margin: 0 !important;
        padding: 0 !important;
        position: relative;
        overflow: visible !important;
    }

    .ant-modal .ant-modal-body {
        width: auto !important;
        height: auto !important;
        border: none !important;
        display: block !important;
        overflow: visible !important;
    }
}

.ant-modal-body h2 {
    margin: 0;
}

.ant-modal-body ul {
    margin: 8px 0;
    padding-inline-start: 24px;
}
