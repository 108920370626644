/* NOTE: Set margin between options */
.input-investments .ant-checkbox-wrapper {
  margin-bottom: 8px;
}

.input-investments .ant-checkbox-wrapper:last-child {
  margin-bottom: 0;
}

/* NOTE: Make input label not clickable */
.input-investments .ant-checkbox-wrapper {
  align-items: inherit;
  pointer-events: none;
}

/* NOTE: enable pointer for checkbox labels, inputs */
.input-investments .ant-checkbox-wrapper .ant-checkbox,
.input-investments .ant-checkbox-wrapper .ant-input-number,
.input-investments .ant-checkbox-wrapper .checkbox-label {
  pointer-events: auto;
}

/* NOTE: Align checkbox to the top */
.input-investments .ant-checkbox-wrapper .ant-checkbox {
  height: 14px;
  align-self: inherit;
}
