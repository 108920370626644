
.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  width: auto;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  inset-inline-start: 90px;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  width: calc(100% - 95px);
  min-height: 0;
  inset-inline-start: calc(90px - 4px);
}

.ant-timeline .ant-timeline-item:last-child {
  padding-bottom: 0;
}

.ant-timeline-item-label {
  left: 0;
  width: 72px;
  margin-top: -1px;
}
