.phone-input .mask-input .ant-input-affix-wrapper {
  border-top-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 6px;
}

.phone-input .item-required::before {
  color: #ff4d4f;
  content: "*";
  display: inline-block;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  margin-inline-end: 4px;
}
