/* NOTE: Remove original bottom border from navigation component */
.navigation .ant-menu-horizontal {
  border-bottom: none;
}

/* NOTE: Align menu items to the right */
.navigation .ant-menu-horizontal {
  justify-content: flex-end;
}

/* NOTE: Use light blue color for selected menu item */
.navigation .ant-menu-horizontal .ant-typography {
  color: inherit;
}

/* NOTE: Make dropdown menu on mobile bigger */
.navigation-mobile.ant-dropdown .ant-dropdown-menu-item {
  padding: 10px 24px 10px 16px;
}
