.horizontal-oriented {
    gap: 10px;
    flex-flow: nowrap !important;
    margin-bottom: 10px;
    align-items: start;
}

.horizontal-oriented .ant-form-item {
    width: 100% !important;
    align-items: center;
    margin-bottom: 0;
 }

.horizontal-oriented .ant-form-item-row {
    flex-flow: row;
    margin-bottom: 0;
    flex-direction: row;
}

.horizontal-oriented .ant-form-item-row .ant-form-item-label {
    width: 100%;
}
