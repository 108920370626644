/* NOTE: Actions button adds additional padding, so aligning table cell content
         to the middle vertically */
.last-column-action .ant-table .ant-table-row .ant-table-cell:last-child {
  padding-top: 7px;
  padding-bottom: 7px;
}

.last-column-action .ant-table.ant-table-small .ant-table-row .ant-table-cell:last-child {
  padding-top: 3px;
  padding-bottom: 3px;
}

/* NOTE: No bottom border for table header */
.ant-table-wrapper.table-custom-header .ant-table .ant-table-thead .ant-table-cell {
  border-bottom: none;
}

/* NOTE: Full height right border for table custom header cells */
.ant-table-wrapper.table-custom-header .ant-table .ant-table-thead .ant-table-cell::before {
  top: 0 !important;
  height: 100% !important;
  transform: none !important;
}
/* NOTE: Fix background color for sort column in virtual tables */
.ant-table-column-sort {
  background-color: #fafafa;
}

/* NOTE: Do not break lines for column titles */
.ant-table-wrapper .ant-table-column-title {
  white-space: nowrap;
}

/* NOTE: Disable highlight checked row */
.ant-table-wrapper .ant-table-tbody .ant-table-row.ant-table-row-selected >.ant-table-cell,
.ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected >td {
  background: none;
}

.ant-table-wrapper .ant-table-tbody .ant-table-row.ant-table-row-selected:hover,
.ant-table-wrapper .ant-table-tbody >tr.ant-table-row:hover>td {
  background: #fafafa;
}
