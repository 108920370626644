.ant-table-tbody .table-cell-truncate {
  overflow: hidden;
}

.ant-table-tbody .table-cell-truncate * {
  white-space: nowrap;
}

.ant-table-tbody .table-cell-truncate:hover {
  z-index: 1;
  overflow: inherit;
}

.ant-table-tbody .table-cell-truncate:hover > * {
  background-color: rgb(250, 250, 250);
}
