.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

/* table cell height */
.editable-collection .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >tbody>tr>td {
  height: 65px;
}

/* color disabled input */
.ant-input-number-disabled {
  background-color: transparent;
}

.ant-input-number-affix-wrapper-disabled {
  background-color: transparent;
}

.ant-input-number-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
}
