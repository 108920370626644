/* NOTE: Update styles for arrows: */

/* TODO: Arrow styles have to be revised. */
.ant-carousel .slick-arrow {
  color: rgba(255, 255, 255, 0.7);
  width: 40px;
  height: 40px;
  z-index: 1;
  padding: 0;
  display: flex !important;
  font-size: 18px;
  background: rgba(0, 0, 0, 0.7);
  align-items: center;
  border-radius: 50%;
  justify-content: center;
}

.ant-carousel .slick-arrow:hover {
  color: rgba(255, 255, 255, 1) !important;
  background: rgba(0, 0, 0, 0.8);
}

.ant-carousel .slick-arrow.slick-prev {
  left: 10px;
  margin-top: -20px;
}

.ant-carousel .slick-arrow.slick-next {
  right: 10px;
  margin-top: -20px;
}

.slick-arrow::after {
  position: static !important;
}
