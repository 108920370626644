.ant-radio-wrapper {
  display: flex;
}

.ant-radio-wrapper .ant-radio {
  top: 3px;
  align-self: start;
}

/* NOTE: Card with inner type has title with bigger font */
.ant-card-type-inner .ant-card-head-title,
.ant-card-type-inner .ant-card-head-title * {
  font-size: 16px;
}

/* NOTE: Summary layout  */
.ant-descriptions.summary .ant-descriptions-item-label {
  font-weight: 600;
}

.ant-descriptions.summary .ant-descriptions-item-label:after {
  display: none;
}

.ant-descriptions.summary .ant-descriptions-row > th {
  padding-bottom: 0;
}

/* NOTE: Compact style witout outer border */
.ant-descriptions.compact .ant-descriptions-view {
  border-radius: 0;
  border-left: none;
  border-right: none;
}

/* NOTE: Make labels black and bold */
.ant-descriptions.black .ant-descriptions-item-label,
.ant-descriptions.black .ant-descriptions-item-label * {
  color: #333333;
  font-weight: 600;
}

/* NOTE: Align labels to the top */
.ant-descriptions .ant-descriptions-item-label {
  vertical-align: top;
}

/* NOTE: Added break space for message */
.ant-message .ant-message-notice .ant-message-notice-content {
  white-space: break-spaces;
}

/* NOTE: Change month range input width */
.month-picker .ant-picker-input {
  width: 56px;
}

/* NOTE: Change date range input width */
.day-picker .ant-picker-input {
  width: 80px;
}

/* NOTE: No border styles for descriptions inside of collapse */
.descriptions
.ant-collapse .ant-collapse-item .ant-collapse-header {
  padding-left: 0;
  padding-right: 0;
}

.descriptions
.ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
  padding: 0;
}

.descriptions
.ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box
.ant-descriptions .ant-descriptions-view {
  border-top: 1px solid #d9d9d9;
  border-bottom: none;
}

.descriptions
.ant-collapse .ant-collapse-item:last-child .ant-collapse-content .ant-collapse-content-box
.ant-descriptions .ant-descriptions-view {
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}

/* NOTE: Hide bottom border on tables */
.descriptions
.ant-table .ant-table-summary .ant-table-cell {
  border-bottom: none;
}

/* NOTE: Invert colors for steps component */
.steps-inverted-colors .ant-steps-item-process:not(.ignore-inverted-colors) .ant-steps-item-icon {
  background-color: #e6f4ff !important;
  border-color: #e6f4ff !important;
}

.steps-inverted-colors .ant-steps-item-process:not(.ignore-inverted-colors) .ant-steps-item-icon >.ant-steps-icon {
  color: #1677ff !important;
}

.steps-inverted-colors .ant-steps-item-finish:not(.ignore-inverted-colors) .ant-steps-item-icon {
  background-color: #1677ff !important;
  border-color: #1677ff !important;
}

.steps-inverted-colors .ant-steps-item-finish:not(.ignore-inverted-colors) .ant-steps-item-icon .ant-steps-icon{
  color: white !important;
}

