.string-list-input .ant-empty {
    display: none;
}

.string-list-input .ant-table-wrapper .ant-table-footer {
    background: none;
    display: block;
    height: 60px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
}

.string-list-input .ant-table-row {
    background: rgba(0, 0, 0, 0.04);
}

.string-list-input .ant-table-cell {
    padding: 0 8px 0 8px !important;
    border: none !important;
}

.string-list-input .ant-table-row {
    margin-bottom: 8px;
    display: grid;
}
