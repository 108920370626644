/* NOTE: Increase opacity on hover to make button interactive */
.view-more-button.ant-btn:hover {
  background-color: rgba(0, 0, 0, 0.90) !important;
}

/* NOTE: Make modal transparent */
.photos-modal.ant-modal .ant-modal-content {
  background-color: transparent;
  border-radius: none;
  box-shadow: none;
  padding: 0;
}

/* NOTE: Stick close button and make it white */
.photos-modal.ant-modal .ant-modal-close {
  color: #FFFFFF;
  position: sticky;
}
